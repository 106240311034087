import './App.css';
import {BrowserRouter as Router, Route, Routes, NavLink} from 'react-router-dom'
import axios from 'axios';
import Swal from 'sweetalert2';
import navigation from './sources/navigation.json'
import headlines from './sources/headlines.json'
import what_is from './sources/whats_is.json'
import apps from './sources/apps.json'
import benefit from './sources/benefit.json'
import important from './sources/important.json'
import features from './sources/features.json'
import use_cases from './sources/use_cases.json'
import {useState, useEffect} from 'react'
import * as gaEvents from "./ga-utils"
import { useCookies, Cookies } from "react-cookie"
import CookiePolicy from './CookiePolicy'
import PrivacyPolicy from './PrivacyPolicy'
import TermsConditions from './TermsConditions'
import prices from './prices'

function App() {
    const [table, setTable] = useState(false)

	//cookie consent
	const [cookiesAll, setCookieAll] = useCookies(["_cookieConsentAll"]);
	const [cookiesGA, setCookieGA] = useCookies(["_cookieConsentGA"]);
	const [cookiesMS, setCookieMS] = useCookies(["_cookieConsentMS"]);
	const [cookiesAsk, setCookieAsk] = useCookies(["_cookieConsentAsk"]);

	const [msState, setMsState] = useState(true)
	const [gaState, setGaState] = useState(true)

	const [showOptions, setShowOptions] = useState(false)

	const [showOptionsNS, setShowOptionsNS] = useState(false)
	const [showOptionsGA, setShowOptionsGA] = useState(false)
	const [showOptionsMS, setShowOptionsMS] = useState(false)


	//contact form
	const [contactButton, setContactButton] = useState('Send Message')
	const [contactEmail, setContactEmail] = useState('')
	const [contactSubject, setContactSubject] = useState('')
	const [contactMessage, setContactMessage] = useState('')



	const cookies = new Cookies()

	const showCookieOptions = () =>{
		setShowOptions(!showOptions)
		if(showOptions===true){
			document.querySelector(".arrow-custom").style.transform = "rotate(0deg)"
		}
		if(showOptions===false){
			document.querySelector(".arrow-custom").style.transform = "rotate(-180deg)"
		}
	}

	const handleAcceptCookie = () =>{
		setCookieAll("_cookieConsentAll", "true", {
			path: "/", maxAge : 31536000, sameSite: true, secure: true, domain:'storesyncer.com'
		  });
		setCookieAsk("_cookieConsentAsk", "false", {
			path: "/", maxAge : 31536000, sameSite: true, secure: true, domain:'storesyncer.com'
		});
		//enable ga4 and clarity
		window.clarity('consent');
		gaEvents.initGA(`${process.env.REACT_APP_GA_ID}`)
	}

	const handleAcceptCookieCustom = () =>{
		const allCookies = cookies.getAll();

		Object.keys(allCookies).forEach(cookieName => {
			cookies.remove(cookieName, {domain:'.storesyncer.com' , path: '/' });
		});


		setCookieAsk("_cookieConsentAsk", "false", {
			path: "/", maxAge : 31536000, sameSite: true, secure: true, domain:'storesyncer.com'
		});


		if(msState===true){
			setCookieMS("_cookieConsentMS", "true", {
				path: "/", maxAge : 31536000, sameSite: true, secure: true, domain:'storesyncer.com'
			});
			window.clarity('consent');
		}
		else{
			setCookieMS("_cookieConsentMS", "false", {
				path: "/", maxAge : 31536000, sameSite: true, secure: true, domain:'storesyncer.com'
			});
		}


		if(gaState===true){
			setCookieGA("_cookieConsentGA", "true", {
				path: "/", maxAge : 31536000, sameSite: true, secure: true, domain:'storesyncer.com'
			});
			gaEvents.initGA(`${process.env.REACT_APP_GA_ID}`)
		}
		else{
			setCookieGA("_cookieConsentGA", "false", {
				path: "/", maxAge : 31536000, sameSite: true, secure: true, domain:'storesyncer.com'
			});

		}
	}

	const handleDeclineCookie = () => {

		const allCookies = cookies.getAll();

		Object.keys(allCookies).forEach(cookieName => {
				cookies.remove(cookieName, {domain:'.storesyncer.com' , path: '/' })
		});

			setCookieAll("_cookieConsentAll", "false", {
				path: "/", maxAge : 31536000, sameSite: true, secure: true, domain:'storesyncer.com'
			});
			setCookieAsk("_cookieConsentAsk", "false", {
				path: "/", maxAge : 31536000, sameSite: true, secure: true, domain:'storesyncer.com'
			});
    };

	useEffect(()=>{
		//cookie check

		if(cookiesAsk._cookieConsentAsk !== "false"){
			const allCookies = cookies.getAll();
			

			Object.keys(allCookies).forEach(cookieName => {
				cookies.remove(cookieName, {domain:'.storesyncer.com' , path: '/' });
			});

			setCookieAsk("_cookieConsentAsk", "true", {
				path: "/", maxAge : 31536000, sameSite: true, secure: true, domain:'storesyncer.com'
			});
		}


		if (cookiesAll._cookieConsentAll === "true") {
			gaEvents.initGA(`${process.env.REACT_APP_GA_ID}`)
			window.clarity('consent');
		}else if(cookiesAll._cookieConsentAll === "false"){
			const allCookies = cookies.getAll();

			Object.keys(allCookies).forEach(cookieName => {
				if(cookieName!=='_cookieConsentAll' && cookieName!=='_cookieConsentAsk' && !(cookieName==="_cookieConsentGA"&&cookiesGA._cookieConsentGA === "true") && !(cookieName==="_cookieConsentMS"&&cookiesMS._cookieConsentMS === "true")){
					cookies.remove(cookieName, {domain:'.storesyncer.com' , path: '/' })
				}
			});
    	}


		if (cookiesGA._cookieConsentGA === "true") {
			gaEvents.initGA(`${process.env.REACT_APP_GA_ID}`)
		}

		if (cookiesMS._cookieConsentMS === "true") {
			window.clarity('consent');
		}

	},[])



	const openContact = () =>{
		if(document.getElementById('contact-form').style.width === "300px"){
			let items = document.getElementsByClassName("contact-item")
			for(let i = 0; i<items.length;i++){
				items[i].style.display='none'
			}
		}
		else{
			setTimeout(()=>{
				let items = document.getElementsByClassName("contact-item")
				for(let i = 0; i<items.length;i++){
					items[i].style.display='block'
				}
			},100)
		}


		if(document.getElementById('contact-form').style.width === "300px"){
				document.getElementById('contact-form').style.width = "0"
				document.getElementById('contact-form').style.height = "0"
				document.getElementById('contact-form').style.padding = "0"
		}
		else{
			document.getElementById('contact-form').style.width = "300px"
			document.getElementById('contact-form').style.height = "436.6px"
			document.getElementById('contact-form').style.padding = "15px"

		}
	}

	const closeContact = (e) =>{
		if(document.getElementById('contact-form').style.width === "300px" && (e.target.id !== 'contact-icon' && e.target.id !== 'contact-form' && e.target.id !== 'contact-item1' && e.target.id !== 'contact-item2' && e.target.id !== 'contact-item3' && e.target.id !== 'contact-item4')){
			document.getElementById('contact-form').style.width = "0"
			document.getElementById('contact-form').style.height = "0"
			document.getElementById('contact-form').style.padding = "0"
			let items = document.getElementsByClassName("contact-item")
			for(let i = 0; i<items.length;i++){
				items[i].style.display='none'
			}
		}
	}

	const sendMessage = (e) =>{
		e.preventDefault()
		setContactButton('')
		var data = JSON.stringify({
			"email":contactEmail,
			"subject":`Message From StoreSyncer Landing Page - ${contactSubject} - ${contactEmail}`,
			"message": contactMessage
		});

		var config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${process.env.REACT_APP_API_URL}/support`,
			headers: {'Content-Type': 'application/json' },
			data : data
		};

		axios(config)
		.then(function (response) {
			gaEvents.sendMessageFloatingFormSuccess()//form event

			setContactButton('Send Message')
			setContactEmail('')
			setContactSubject('')
			setContactMessage('')
			Swal.fire({
				title: 'Success',
				text: "Email sent successfully!",
				icon: 'success',
				showCancelButton: false,
				confirmButtonColor: '#3085d6',
				confirmButtonText: 'OK'
			  })
		})
		.catch(function (error) {
			gaEvents.sendMessageFloatingFormFail()//form event

			setContactButton('Send Message')
			Swal.fire({
				title: 'Error',
				text: "Can not send email! Try again later.",
				icon: 'error',
				showCancelButton: false,
				confirmButtonColor: '#3085d6',
				confirmButtonText: 'OK'
			  })
		});

	}


	// newsletter popup
	const [newsletter, setNewsletter] = useState(false)
	const [newsletterMail, setNewsletterMail] = useState("")
	const [subState, setSubState] = useState(false)
	const [subButton, setSubButton] = useState("Subscribe")
	useEffect(()=>{
		if(localStorage.getItem("subscription")){
			// do nothing
		}
		else{
			function timeout(miliseconds) {
				return new Promise( res => setTimeout(res, miliseconds) );
			}
			(async ()=>{
				timeout(15000).then(()=>{
					setNewsletter(true)
					setTimeout(()=>{
						document.getElementById("newsLetterModal").style.opacity = 1
					},100)
				})
			})()
		}
	},[])

	const subscribe = (e) =>{
		e.preventDefault()
		setSubState(true)
		let data = JSON.stringify({
			"email": newsletterMail
		  });
		  
		  let config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${process.env.REACT_APP_API_URL}/emailSubscription/subscribe`,
			headers: { 
			  'Content-Type': 'application/json'
			},
			data : data
		  };
		  
		  axios.request(config)
		  .then((response) => {
			localStorage.setItem("subscription", true)
			setSubState(false)
			setSubButton("Done!")
			setTimeout(()=>{
				document.getElementById("newsLetterModal").style.opacity=0
			},400)
			setTimeout(()=>{
				setNewsletter(false)
			},800)
			setTimeout(()=>{
				setNewsletterMail("")
				setSubButton("Subscribe")
			},1000)
		  })
		  .catch((error) => {
			setSubState(false)
			setSubButton("Subscribe")
			Swal.fire({
				title: 'Error',
				text: "Something went wrongi please try again later.",
				icon: 'error',
				showCancelButton: false,
				confirmButtonColor: '#3085d6',
				confirmButtonText: 'OK'
			  })
			
		  });
	}







	//responsive menu
	const [windowWidth, setWindowWidth] = useState(window.innerWidth)
	const setWindowDimensions = () => {
		setWindowWidth(window.innerWidth)
	}

	window.onscroll = function() {		
		if (document.documentElement.scrollTop >= 80 && (window.location.pathname==='/' || window.location.pathname==='') ) {
			document.getElementById("nav-menu").style.padding = "15px 10px";
			document.getElementById("nav-menu").style.backgroundColor = "#fff";
			document.getElementById("mobile-nav").style.top="14px" 
		
		} else if (window.location.pathname==='/' || window.location.pathname==='') {
			document.getElementById("nav-menu").style.padding = "45px 30px";
			document.getElementById("nav-menu").style.backgroundColor = "transparent";
			document.getElementById("mobile-nav").style.top="45px" 

			
		}
	};
	

	useEffect(()=>{
		window.addEventListener('resize', setWindowDimensions);
		return () => {
			window.removeEventListener('resize', setWindowDimensions)
		}
	},[])

	useEffect(()=>{
		if(window.location.pathname==='/' || window.location.pathname===''){
			if(windowWidth>872 && document.getElementById('mobile-nav-menu').style.width !== "0px"){
					document.getElementById('mobile-nav-icon').src = '/assets/Icons/hamburger-menu-icon.svg'
					document.getElementById('mobile-nav-menu').style.width = "0"
					document.getElementById('mobile-nav-menu').style.height = "0"
					let items = document.getElementsByClassName("mobile-menu-item")
					for(let i = 0; i<items.length;i++){
						items[i].style.display='none'
					}
			}
		}
	},[windowWidth])

	const openMobileMenu = () =>{

		if(document.getElementById('mobile-nav-menu').style.width === "250px"){
			document.getElementById('mobile-nav-icon').src = '/assets/Icons/hamburger-menu-icon.svg'
			let items = document.getElementsByClassName("mobile-menu-item")
			for(let i = 0; i<items.length;i++){
				items[i].style.display='none'
			}
		}
		else{
			setTimeout(()=>{
				let items = document.getElementsByClassName("mobile-menu-item")
				for(let i = 0; i<items.length;i++){
					items[i].style.display='block'
				}
			},100)
		}


		if(document.getElementById('mobile-nav-menu').style.width === "250px"){
			
				document.getElementById('mobile-nav-menu').style.width = "0"
				document.getElementById('mobile-nav-menu').style.height = "0"
			
		}
		else{
			document.getElementById('mobile-nav-icon').src = '/assets/Icons/hamburger-menu-icon-dark.svg'

			document.getElementById('mobile-nav-menu').style.width = "250px"
			document.getElementById('mobile-nav-menu').style.height = "401px"
		}
		
	}

	const closeMenu = (e) =>{
		if(document.getElementById('mobile-nav-menu').style.width === "250px" && e.target.id !== 'mobile-nav-icon'){
			document.getElementById('mobile-nav-menu').style.width = "0"
			document.getElementById('mobile-nav-menu').style.height = "0"
			document.getElementById('mobile-nav-icon').src = '/assets/Icons/hamburger-menu-icon.svg'
			let items = document.getElementsByClassName("mobile-menu-item")
			for(let i = 0; i<items.length;i++){
				items[i].style.display='none'
			}
		}
	}



	//headlines

	const [headline, setHeadline] = useState(headlines.headlines[0])

	useEffect(()=>{
		function timeout(miliseconds) {
			return new Promise( res => setTimeout(res, miliseconds) );
		}
		(async ()=>{
			for(let i = 0; ; i++){
				setHeadline(headlines.headlines[i])
				if(i===headlines.headlines.length-1){
					i=-1
				}
				await timeout(headlines.miliseconds);
			}
			
		})()
	},[])


	//use-cases
	const [modal, setModal] = useState({show: false, url:''})

	const showModal = (link, index) =>{
		setModal({show:true, url:link})
	}





  return (
	<Router>
		<div className="App">
			{/* cookie consent */}

			{cookiesAsk._cookieConsentAsk === "true" ?
				<div className='cookieConsent'>

					<div className='cookieConsent-inner-up'>
						<div className='cookie-text'>
							<h3>We Use Cookies</h3>
							<p>
								We may place cookies for analysis of our visitor data, to improve our website and measure advertising performance. Overall this data is used to provide a better website experience. For more information about the cookies we use, click the <strong>"Customize"</strong> button. <a rel="noopener noreferrer" target='_blank' href="https://www.storesyncer.com/privacypolicy">Privacy Policy</a> <a rel="noopener noreferrer" target='_blank' href="https://www.storesyncer.com/cookiepolicy">Cookie Policy</a> <a rel="noopener noreferrer" target='_blank' href="https://www.storesyncer.com/termsConditions">Terms and Conditions</a>
							</p>
						</div>
						<div className='cookie-buttons'>
							<button onClick={showCookieOptions} className='customize'>Customize <span className='arrow-custom'></span></button>
							<button onClick={handleDeclineCookie} className='decline-all'>Decline All</button>
							<button onClick={handleAcceptCookie} className='accept-all'>Accept All</button>
						</div>
					</div>

					{showOptions===true ? <>
						<div className='cookieConsent-inner-down'>

							<div className='necessary cookie-type'>
								<div className='cookie-type-content'>
									<h3>Necessary</h3>
									<p>These cookies are required for good functionality of our website and can't be switched off in our system.</p>
									<p onClick={()=>setShowOptionsNS(!showOptionsNS)} style={{textDecoration:'underline', fontStyle:'italic'}}>
										{showOptionsNS ? 'Show Less' : 'Show More'}
									</p>
								</div>
								<div className='cookie-type-switch'>
									<label className="switch">
										<input checked={true} type="checkbox" disabled={true}/>
										<span className="slider round disabled-input"></span>
									</label>
								</div>
							</div>

							{showOptionsNS ? 
								<div className='cookie-details'>
									<h4>_cookieConsentAsk</h4>
									<p>This cookie is to determine whether to ask user for cookie consents.</p>
									<p style={{fontStyle:'italic'}}> Domain name: storesyncer.com - Expiration time: 1 year - Type: Cookie</p>
									<h4>_cookieConsentGA</h4>
									<p>This cookie is to determine whether user accepts Google Analytics cookies.</p>
									<p style={{fontStyle:'italic'}}> Domain name: storesyncer.com - Expiration time: 1 year - Type: Cookie</p>
									<h4>_cookieConsentMS</h4>
									<p>This cookie is to determine whether user accepts Microsoft Clarity and Bing cookies.</p>
									<p style={{fontStyle:'italic'}}> Domain name: storesyncer.com - Expiration time: 1 year - Type: Cookie</p>
									<h4>_cookieConsentAll</h4>
									<p>This cookie is to determine whether user accepts all cookies.</p>
									<p style={{fontStyle:'italic'}}> Domain name: storesyncer.com - Expiration time: 1 year - Type: Cookie</p>
								</div>
							: ''
							}


							<hr />

							<div className='analytics cookie-type'>
								<div className='cookie-type-content'>
									<h3>Analytics</h3>
									<p>We use these cookies to provide statistical information about our website - they are used for performance measurement, improvement and analysis.</p>
									<p onClick={()=>setShowOptionsGA(!showOptionsGA)} style={{textDecoration:'underline', fontStyle:'italic'}}>
										{showOptionsGA ? 'Show Less' : 'Show More'}
									</p>
									{showOptionsGA ? 
										<div className='cookie-heading'>
											<img style={{width:'25px'}} src="/assets/Icons/google-analytics-icon.png" alt="GA4" />
											<h4>Google Analytics</h4>
										</div>
									: ''
									}
								</div>
								<div className='cookie-type-switch'>
									<label className="switch">
										<input checked={gaState} onChange={(e)=>setGaState(e.target.checked)} type="checkbox"/>
										<span style={{cursor:'pointer'}} className="slider round"></span>
									</label>
								</div>
							</div>

							{showOptionsGA ? 
								<div className='cookie-details'>
									<h4>_ga</h4>
									<p>Registers a unique ID for a website visitor it tracks how the visitor uses the website. The data is used for statistics.</p>
									<p style={{fontStyle:'italic'}}> Domain name: storesyncer.com - Expiration time: 2 years - Type: Cookie</p>
									
									<h4>_ga_********</h4>
									<p>This cookie stores a unique ID for a website visitor it tracks how the visitor uses the website. The data is used for statistics.</p>
									<p style={{fontStyle:'italic'}}> Domain name: storesyncer.com - Expiration time: 2 years - Type: Cookie</p>

									<p></p>
									<a target='_blank' style={{textDecoration:'underline',color:'#fff', fontStyle:'italic'}} href="https://policies.google.com/privacy?hl=en-US" rel='noopener noreferrer'>Google Privacy Policy</a>
								</div>
							: ''
							}

							<hr />

							<div className='functional cookie-type'>
								<div className='cookie-type-content'>
									<h3>Performance / Advertisement</h3>
									<p>We use these cookies to enhance functionality and improve our site along with cookies set through our site by our advertising partners.</p>
									<p onClick={()=>setShowOptionsMS(!showOptionsMS)} style={{textDecoration:'underline', fontStyle:'italic'}}>
										{showOptionsMS ? 'Show Less' : 'Show More'}
									</p>
									{showOptionsMS ?
										<div className='cookie-heading'>
											<img style={{width:'25px'}} src="/assets/Icons/ms-clarity.png" alt="MS_Clarity" />
											<h4>Microsoft Clarity</h4>
										</div>
									: ''
									}
								</div>
								<div className='cookie-type-switch'>
									<label className="switch">
										<input checked={msState} onChange={(e)=>setMsState(e.target.checked)} type="checkbox"/>
										<span style={{cursor:'pointer'}} className="slider round"></span>
									</label>
								</div>
							</div>

							{showOptionsMS ?
								<div className='cookie-details'>
									<h4>_clsk</h4>
									<p>This cookie connects multiple page views by a user into a single Clarity session recording.</p>
									<p style={{fontStyle:'italic'}}> Domain name: storesyncer.com - Expiration time: 1 day - Type: Cookie</p>
									
									<h4>_clck</h4>
									<p>This cookie collects data about visitor navigation and behavior on the website - This is used to compile statistical reports and heatmaps for the website owner.</p>
									<p style={{fontStyle:'italic'}}> Domain name: storesyncer.com - Expiration time: 1 year - Type: Cookie</p>
									
									<h4>MR</h4>
									<p>This is a Microsoft MSN 1st party cookie which we use to measure the use of the website for internal analytics.</p>
									<p style={{fontStyle:'italic'}}> Domain name: .c.clarity.ms - Expiration time: 7 days - Type: Cookie</p>
									
									<h4>MR</h4>
									<p>This is a Microsoft MSN 1st party cookie which we use to measure the use of the website for internal analytics.</p>
									<p style={{fontStyle:'italic'}}> Domain name: .c.bing.com - Expiration time: 7 days - Type: Cookie</p>
									
									<h4>MUID</h4>
									<p>This cookie is widely used by Microsoft as a unique user ID. It can be set by embedded Microsoft scripts. It is widely believed to synchronize between many different Microsoft domains, allowing users to be tracked.</p>
									<p style={{fontStyle:'italic'}}> Domain name: storesyncer.com - Expiration time: 1 year - Type: Cookie</p>

									<h4>ANONCHK</h4>
									<p>Indicates whether MUID is transferred to ANID, a cookie used for advertising. Clarity doesn't use ANID and so this is always set to 0.</p>
									<p style={{fontStyle:'italic'}}> Domain name: www.clarity.ms - Expiration time: At least one session - Type: Cookie</p>
									
									<h4>CLID</h4>
									<p>This cookie collects data about visitor navigation and behavior on the website - This is used to compile statistical reports and heatmaps for the website owner.</p>
									<p style={{fontStyle:'italic'}}> Domain name: www.clarity.ms - Expiration time: 1 year - Type: Cookie</p>

									<h4>SM</h4>
									<p>This is a Microsoft MSN 1st party cookie that ensures the proper functioning of the website.</p>
									<p style={{fontStyle:'italic'}}> Domain name: .c.clarity.ms - Expiration time: 1 year - Type: Cookie</p>

									<h4>SRM_B</h4>
									<p>This is a Microsoft MSN 1st party cookie that ensures the proper functioning of this website.</p>
									<p style={{fontStyle:'italic'}}> Domain name: .c.bing.com - Expiration time: 1 year - Type: Cookie</p>

									<p></p>
									<a target='_blank' style={{textDecoration:'underline',color:'#fff', fontStyle:'italic'}} href="https://privacy.microsoft.com/en-us/privacystatement" rel='noopener noreferrer'>Microsoft Privacy Policy</a>
								</div>
							: ''
							}


						</div>
						<div style={{maxWidth:'1200px', width:"100%", textAlign:"right"}}>
							<button onClick={handleAcceptCookieCustom} className='saveButton accept-all'>Save Preferences</button>
						</div></>

					: 
						''
					}
				

				</div>
			:
				''
			}
			<Routes>
				<Route exact path='/' element={
					<div onClick={(e)=>{closeMenu(e);closeContact(e)}} className='app-inner'>





						{/* contact icon */}

						<img id='contact-icon' onClick={openContact} className='contact-icon' src='/assets/Icons/Support.svg' alt='contact'/>
						<form onSubmit={sendMessage} id='contact-form' className='contact-form'>
							<input onChange={(e)=>setContactEmail(e.target.value)} value={contactEmail} id='contact-item1' className='contact-item' type='email' placeholder='Email Address' required={true}/>
							<input onChange={(e)=>setContactSubject(e.target.value)} value={contactSubject}  id='contact-item2' className='contact-item' type='text' placeholder='Subject' required={true}/>
							<textarea onChange={(e)=>setContactMessage(e.target.value)} value={contactMessage}  id='contact-item3' className='contact-item' type='text' placeholder='Message' required={true}/>
							<button id='contact-item4' type='submit' className='btn font-white back-orange contact-item' >{contactButton==='' ? <img style={{height:'15px'}} src='/assets/Icons/spinner.gif' alt='loading'/> : contactButton}</button>
						</form>


						{/* navigation */}


						<div id='nav-menu' className='nav-menu '>
							<div className='nav-menu-inner maxw14'>

								<div className='nav-section logo'>
									<div>
										<img onClick={()=>{
												window.scrollTo({top: 0, behavior:'smooth'
											})
										}} src={navigation.logo} alt="StoreSyncer_logo" />	
									</div>
								</div>

								<div className='nav-section menu-items'>
									{navigation.nav.map((menu, index)=>{
					
											return(
												<div key={index} className='menu-item'>
													<p onClick={()=>{
															window.scrollTo({top: document.getElementById(menu.link).getBoundingClientRect().top + window.pageYOffset - 70, behavior:'smooth'
														})
													}}>{menu.text}</p>
												</div>
											)
									})}
									<div className='menu-item'>
										<a onClick={()=>gaEvents.docVisitedNav()} rel="noopener noreferrer" target='_blank' href={navigation.doc.link}>{navigation.doc.text}</a>
									</div>
								</div>

								<div className='nav-section nav-buttons'>
									<a href={navigation.button.link} target='_blank'>
										<button onClick={()=>gaEvents.goToApp()} className='btn back-orange font-white'>{navigation.button.text}</button>
									</a>
								</div>

								<div id='mobile-nav' className='nav-section mobile-nav' onClick={openMobileMenu}>
									<img id='mobile-nav-icon' src="/assets/Icons/hamburger-menu-icon.svg" alt="menu" />
								</div>

								<div id='mobile-nav-menu' className='mobile-nav-menu'>
									{navigation.nav.map((menu, index)=>{
										return(
											<span onClick={()=>{
													window.scrollTo({top: document.getElementById(menu.link).getBoundingClientRect().top + window.pageYOffset - 70, behavior:'smooth'
												})}} key={index} className={index===0 ? 'mobile-menu-item mobile-first-item' : 'mobile-menu-item'}>

												<div>{menu.text}</div>

											</span>
										)
									})}
									<a onClick={()=>gaEvents.docVisitedNav()} className='mobile-menu-item mobile-link1' rel="noopener noreferrer" target='_blank' href={navigation.doc.link}>
										{navigation.doc.text}
									</a>
									<a rel="noopener noreferrer" className='mobile-menu-item mobile-link2' href={navigation.button.link} target='_blank'>
										{navigation.button.text}
									</a>
								</div>
								
							</div>
						</div>


						{/* hero */}


						<div className='hero-slide maxw12'>
							<div className='hero-slide-inner'>

								<h1 className='font-darkblue'>{headline.dark} <span className='font-orange'>{headline.bright}</span></h1>

								<p className='maxw6'>{headline.text}</p>

								<a onClick={()=>gaEvents.goToApp2()} href={navigation.button.link} target='_blank' rel="noopener noreferrer" className='btn hero-btn-link font-white back-lightblue'>Get Started</a>
								{/* <a className='font-lightblue font-bold' href={headlines.tutorial.link} target="_blank" rel="noopener noreferrer">{headlines.tutorial.text}</a> */}


								<video playsInline={true} controls={false} width='100%' autoPlay={true} loop={true} muted={true} poster='https://store-helper-landing.vercel.app/assets/Images/videoPoster.jpg'>
									<source src="https://store-helper-landing.vercel.app/assets/Videos/loowq.mp4" type="video/mp4"/>
								</video>

							</div>
						</div>


						{/* applist */}


						<div className='apps maxw12'>
							<div className='apps-inner'>

								<h2 className='font-grey'><span className='font-bold'>Apps Using</span> StoreSyncer</h2>

								<div className='appList flex-row center-all maxw6 wrap'>

									{apps.apps.map((app, index)=>{
											return(
												<span title={app.name} key={index}>
													<img className='appImage' src={app.icon} alt={app.name}/>
											
												</span>
											)
										})
									}

								</div>
					
							</div>
						</div>


						{/* what is */}


						<div id='what-is' className='what-is maxw12'>
							<div className='what-is-inner'>

								<h1 className='font-darkblue'>What's <span className='font-orange'>StoreSyncer?</span></h1>

								<p className=''>{what_is.text}</p>


							</div>
						</div>


						{/* benefit cards */}


						<div id='benefits' className='cards maxw18'>
							<div className='cards-inner'>
								
								<h1 className='font-darkblue'>Benefits of <span className='font-orange'>StoreSyncer</span></h1>

								<p className='cards-inner-p maxw6'>Everyone who uses App Store Connect is now one step ahead.</p>

								<div className='cardList'>
									{benefit.benefit.map((card, index)=>{
										return(
											<div key={index} className='card'>
												<img className='card-image' src={card.img} alt="card_image" />
												<h2 className='card-title font-bold font-darkblue'>{card.title}</h2>
												<p className='card-p font-grey'>{card.text}</p>
												<a onClick={()=>gaEvents.docVisitedBenefits(card.title)} target='_blank' rel='noopener noreferrer' href={card.link} className='btn back-orange font-white'>Read More</a>
											</div>
										)
									})

									}

								</div>

							</div>
						</div>


						{/* important */}


						<div id='important' className='important'>
							<div className='important-inner'>

								{important.important.map((section, index)=>{
										if(index % 2 ===0 ){
											return(
												<div key={index} className={`important-inner-div ${index===important.important.length-1 || index===0 ? 'first-important' : ''}`} style={index===important.important.length-1 ? {backgroundColor:'#fff'} : {}}>
													<div className='important-div maxw11'>
														<div className='important-div-image'>
														<video playsInline={true} controls={false} width='100%' autoPlay={true} loop={true} muted={true}>
															<source src={section.img} type="video/mp4"/>
														</video>
														</div>
														<div className='important-div-text'>
															<h3 className='font-lightblue'>{section.title}</h3>
															<p>{section.text}</p>
														</div>
													</div>
													{index===0 && <img className='absolute' style={{bottom:'0', left:'0', right:'0', width:'100%', zIndex:'0'}} src="/assets/Images/white-space.png" alt='white'/>}
												</div>
											)
										}
										else{
											return(
												<div key={index} className='important-inner-div' style={index===1 ? {backgroundColor: "#f0f8ff63"} : {}}>
													<div className='important-div maxw11 odd-div'>
														<div className='important-div-text'>
															<h3 className='font-lightblue'>{section.title}</h3>
															<p>{section.text}</p>
														</div>
														<div className='important-div-image'>
															<video playsInline={true} controls={false} width='100%' autoPlay={true} loop={true} muted={true}>
																<source src={section.img} type="video/mp4"/>
															</video>
														</div>
													</div>
												</div>
											)
										}
									})

								}

							</div>
						</div>


						{/* features */}


						<div id='features' className='features maxw12'>
							<div className='features-inner'>
								
								<h1 className='font-darkblue'>Boots Your Productivity with <span className='font-orange'>StoreSyncer</span></h1>

								<div className='feature-first' style={{backgroundImage:`url(${features.first_feature.img})`}}>
									<div className='feature-first-text'>
										<h2>{features.first_feature.title}</h2>
										<p>{features.first_feature.text}</p>
										<a onClick={()=>gaEvents.docVisitedFeatures(features.first_feature.title)} target='_blank' rel='noopener noreferrer' href={features.first_feature.link}>
											<button className='btn font-orange backgound-white'>Read More</button>
										</a>
									</div>
									<div className='feature-first-image'>
										<img src={features.first_feature.photo} alt={features.first_feature.alt} />
									</div>
								</div>

								<div className='feature-rest'>

									{features.rest_of_feaures.map((row, index)=>{
										return(
											<div key={index} className='feature-row'>
												{row.row.map((feature, index)=>{
													return(
														<div key={'feuature'+index} className='feature' style={{backgroundImage:`url(${feature.img})`}}>
															<a onClick={()=>gaEvents.docVisitedFeatures(feature.title)} target='_blank' rel='noopener noreferrer' href={feature.link}>
																<img src={feature.photo} alt={feature.alt} />	
															</a>
															<h2>{feature.title}</h2>
															<p>{feature.text}</p>
														</div>

													)})
												}
											</div>
										)})
									}
									
								</div>

								<div className='feature-last' style={{backgroundImage:`url(${features.last_feature.img})`}}>
									<div className='feature-first-image'>
										<img src={features.last_feature.photo} alt={features.last_feature.alt} />
									</div>
									<div className='feature-first-text'>
										<h2>{features.last_feature.title}</h2>
										<p>{features.last_feature.text}</p>
										<a onClick={()=>gaEvents.docVisitedFeatures(features.last_feature.title)} rel='noopener noreferrer' target='_blank' href={features.last_feature.link}>
											<button className='btn font-orange backgound-white'>Read More</button>
										</a>
									</div>
								</div>
						
							</div>
						</div>


						{/* Pricing */}


						<h1 id='price-table' style={{padding:'90px 0 50px 0'}} className='font-darkblue'>Pricing</h1>

						<div className="switch-price">
							<span className="choicePrice" style={!table ? {color:'#3A6FF8', fontWeight:'600'} : {fontWeight:'600'}}>Monthly</span>
							<label className="switch2">
								<input type="checkbox" onChange={(e)=>setTable(e.target.checked)}/>
								<span className="slider2 round2"></span>
							</label>
							<span className="choicePrice"  style={table ? {color:'#3A6FF8', fontWeight:'600'} : {fontWeight:'600'}}>Yearly</span>
						</div>

						<div id="priceTable" className="price-table">

							<div className="table13 table">
								<div className="table13-inner">
									<h3></h3>
									<span className="table-price" style={{margin:' 13px 0'}}>
									<span className="table-price-span">Free</span>
									</span>
									<div className="table-btn-div">
										<a target='_blank' rel='noreferrer noopener' href={navigation.appUrl} onClick={()=>gaEvents.goToApp4()} className="free-btn">Sign Up</a>
										<span>{prices.free.subtitle}</span>
									</div>
									<div className="table-desc-div">
										<hr />
										<span>
											{prices.free.prompt}
										</span>
										<hr />
									</div>
									<ul>
										<li>
											{prices.free.apps.flag ?
												<img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
											:
												<img src="/assets/Icons/x.svg" alt="negative_mark" /> 
											}
											<span>
												{prices.free.apps.text}
											</span>
										</li>

										<li>
											{prices.free.collections.flag ?
												<img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
											:
												<img src="/assets/Icons/x.svg" alt="negative_mark" /> 
											}
											<span>
												{prices.free.collections.text}
											</span>
										</li>

										<li>
											{prices.free.team.flag ?
												<img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
											:
												<img src="/assets/Icons/x.svg" alt="negative_mark" /> 
											}
											<span>
												{prices.free.team.text}
											</span>
										</li>

										<li>
											{prices.free.metadata.flag ?
												<img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
											:
												<img src="/assets/Icons/x.svg" alt="negative_mark" /> 
											}
											<span>
												{prices.free.metadata.text}
											</span>
										</li>

										<li>
											{prices.free.importExportPreview.flag ?
												<img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
											:
												<img src="/assets/Icons/x.svg" alt="negative_mark" /> 
											}
											<span>
											{prices.free.importExportPreview.text}
											</span>
										</li>

										<li>
											{prices.free.uploads.flag ?
												<img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
											:
												<img src="/assets/Icons/x.svg" alt="negative_mark" /> 
											}
											<span>
												{prices.free.uploads.text}
											</span>
										</li>
									</ul>
								</div>

							</div>

							<div className="table2 table">
									<div className="table-title-div">
										<h3>Premium</h3>
										<span style={{backgroundColor:'gold', color:'#000', fontSize:'0.9rem', padding:'2px 9px',borderRadius:'15px', color:'#0f044c'}}>Most Popular</span>
									</div>
									<span className="table-price">
									{!table ?
										<>
											<span className="table-price-span">{prices.premium.month}</span>/month
										</>
									:
										<>
											<span className="table-price-span">{prices.premiumYear.month}</span><span>/month*</span><br /><span style={{fontSize:'0.85rem', fontStyle:'italic'}}>*Pay 2 months less than monthly plan</span>
										</> 
									}
									
									</span>
									<div className="table-btn-div">
										<a target='_blank' rel='noreferrer noopener' href={navigation.appUrl} onClick={()=>gaEvents.goToApp5()}>
											Start Trial
										</a>    
										<span>{prices.premium.subtitleTrial}</span>
									</div>
									<div className="table-desc-div">
										<hr />
										<span>
										{prices.premium.prompt}
										</span>
										<hr />
									</div>
									<ul>
										<li>
											{prices.premium.apps.flag ?
												<img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
											:
												<img src="/assets/Icons/x.svg" alt="negative_mark" /> 
											}
											<span>
											{prices.premium.apps.text}
											</span>
										</li>

										<li>
											{prices.premium.collections.flag ?
												<img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
											:
												<img src="/assets/Icons/x.svg" alt="negative_mark" /> 
											}
											<span>
												{prices.premium.collections.text}
											</span>
										</li>

										<li>
											{prices.premium.team.flag ?
												<img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
											:
												<img src="/assets/Icons/x.svg" alt="negative_mark" /> 
											}
											<span>
												{prices.premium.team.text}
											</span>
										</li>

										<li>
											{prices.premium.metadata.flag ?
												<img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
											:
												<img src="/assets/Icons/x.svg" alt="negative_mark" /> 
											}
											<span>
												{prices.premium.metadata.text}
											</span>
										</li>

										<li>
											{prices.premium.importExportPreview.flag ?
												<img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
											:
												<img src="/assets/Icons/x.svg" alt="negative_mark" /> 
											}
											<span>
												{prices.premium.importExportPreview.text}
											</span>
										</li>

										<li>
											{prices.premium.uploads.flag ?
												<img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
											:
												<img src="/assets/Icons/x.svg" alt="negative_mark" /> 
											}
											<span>
												{prices.premium.uploads.text}
											</span>
										</li>
									</ul>

							</div>

							<div className="table13 table">
								<div className="table13-inner">

								<div className="table-title-div">
									<h3>Premium Plus</h3>
									<span style={{backgroundColor:'gold', color:'#000', fontSize:'0.9rem', padding:'2px 9px',borderRadius:'15px', color:'#0f044c'}}>Best Value</span>
								</div>
								<span className="table-price">
									{!table ?
									<>
										<span className="table-price-span">{prices.premiumPlus.month}</span>/month
									</>
									:
									<>
										<span className="table-price-span">{prices.premiumPlusYear.month}</span><span>/month*</span><br /><span style={{fontSize:'0.85rem', fontStyle:'italic'}}>*Pay 2 months less than monthly plan</span>
									</> 
									}
								</span>
								<div className="table-btn-div">
									<a target='_blank' rel='noreferrer noopener' href={navigation.appUrl} onClick={()=>gaEvents.goToApp6()}>
										Get Started
									</a>
									<span>{prices.premiumPlus.subtitle}</span>
								</div>
								<div className="table-desc-div">
									<hr />
									<span>
										{prices.premiumPlus.prompt}
									</span>
									<hr />
								</div>
								<ul>
										<li>
											{prices.premiumPlus.apps.flag ?
												<img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
											:
												<img src="/assets/Icons/x.svg" alt="negative_mark" /> 
											}
											<span>
											{prices.premiumPlus.apps.text}
											</span>
										</li>

										<li>
											{prices.premiumPlus.collections.flag ?
												<img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
											:
												<img src="/assets/Icons/x.svg" alt="negative_mark" /> 
											}
											<span>
												{prices.premiumPlus.collections.text}
											</span>
										</li>

										<li>
											{prices.premiumPlus.team.flag ?
												<img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
											:
												<img src="/assets/Icons/x.svg" alt="negative_mark" /> 
											}
											<span>
												{prices.premiumPlus.team.text}
											</span>
										</li>

										<li>
											{prices.premiumPlus.metadata.flag ?
												<img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
											:
												<img src="/assets/Icons/x.svg" alt="negative_mark" /> 
											}
											<span>
												{prices.premiumPlus.metadata.text}
											</span>
										</li>

										<li>
											{prices.premiumPlus.importExportPreview.flag ?
												<img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
											:
												<img src="/assets/Icons/x.svg" alt="negative_mark" /> 
											}
											<span>
												{prices.premiumPlus.importExportPreview.text}
											</span>
										</li>

										<li>
											{prices.premiumPlus.uploads.flag ?
												<img src="/assets/Icons/tik.svg" alt="positive_mark" /> 
											:
												<img src="/assets/Icons/x.svg" alt="negative_mark" /> 
											}
											<span>
												{prices.premiumPlus.uploads.text}
											</span>
										</li>

									</ul>
								</div>
							</div>

						</div>


						{/* use cases */}


						<div id='use-cases' className='use-cases maxw18'>
							<div className='use-cases-inner'>
								
								<h1 className='font-darkblue'>Example Use Cases</h1>

								<div className='use-caseList'>
									{use_cases.use_cases.map((use_case, index)=>{
										return(
											<div key={index} className='use-case'>
												<img className='use-case-image' src={use_case.img} alt="use-case" />
												<h2 className='use-case-title font-bold font-darkblue'>{use_case.title}</h2>
												<p className='use-case-p font-grey'>{use_case.text}</p>
												<button onClick={()=>{showModal(use_case.link,index);gaEvents.useCasesWatched(use_case.title)}} className='btn back-orange font-white'>Learn More</button>
											</div>
										)})
									}
								</div>

								{modal.show ? 
									<div className='use-case-modal'>
										<div className='use-case-modal-back' onClick={()=>setModal(false)}></div>
										<div className='use-case-modal-content'>
											<iframe 
												width="100%" 
												height="100%" 
												style={{border:'none'}}
												src={modal.url} 
												title="YouTube video player" 
												allow="accelerometer; autoplay; clipboard-write; web-share" 
												allowFullScreen={true}>
											</iframe>
										</div>
									</div>
								:
									''
								}


							</div>
						</div>




						{/* Footer */}


						<div className='footer'>
							<div className='footer-inner maxw12'>
								<div className='footer-logo-div'>
									<div className='footer-logo'>
										<NavLink to="/">
											<img src="/assets/Images/Logo-Home.svg" alt="logo" />	
										</NavLink>
									</div>
								</div>
								<div className='footer-section footer-quick-links'>
									<p className='font-bold' style={{marginBottom:'20px', color:'#2A2C33'}}>Quick Links</p>
									{navigation.nav.map((menu, index)=>{
										return(
											<span key={index} onClick={()=>{
													window.scrollTo({top: document.getElementById(menu.link).getBoundingClientRect().top + window.pageYOffset - 70, behavior:'smooth'
												})
											}}>{menu.text}</span>
										)
									})}
								</div>
								<div className='footer-section footer-others'>
									<p className='font-bold' style={{marginBottom:'20px', color:'#2A2C33'}}>Help Center</p>
									<a onClick={()=>{gaEvents.docVisitedFooter()}} rel="noopener noreferrer" target='_blank' href={navigation.doc.link}>{navigation.doc.text}</a>
									<a rel="noopener noreferrer" target='_blank' href="https://www.storesyncer.com/privacypolicy">Privacy Policy</a>
									<a rel="noopener noreferrer" target='_blank' href="https://www.storesyncer.com/cookiepolicy">Cookie Policy</a>
									<a rel="noopener noreferrer" target='_blank' href="https://www.storesyncer.com/termsConditions">Terms and Conditions</a>
								</div>
								<div className='footer-section footer-contact-us'>
									<p className='font-bold' style={{marginBottom:'20px', color:'#2A2C33'}}>Get Started</p>
									<a onClick={()=>gaEvents.goToApp3()} rel="noopener noreferrer" target='_blank' href={navigation.appUrl}>Sign Up</a>
								</div>
							</div>
							<div className='footer-bottom font-grey'>
								© 2023 StoreSyncer All Rights Reserved
							</div>
						</div>

						<a href="https://www.producthunt.com/posts/storesyncer?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-storesyncer" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=391513&theme=light" alt="StoreSyncer - StoreSyncer&#0058;&#0032;Simplifying&#0032;App&#0032;Management&#0032;on&#0032;App&#0032;Store&#0032;Connect | Product Hunt" style={{position: "fixed", bottom:"20px", left:"15px",width: "250px", height:" 54px", zIndex:'97'}} width="250" height="54" /></a>

						{newsletter ? 
							<div id='newsLetterModal' className='newsLetter-modal'>
								<div className='newsLetter-back' onClick={()=>{document.getElementById('newsLetterModal').style.opacity= 0;setTimeout(()=>{setNewsletter(false)},400)}}></div>
								<div className='newsLetter-close font-bold font-white' onClick={()=>{document.getElementById('newsLetterModal').style.opacity= 0;setTimeout(()=>{setNewsletter(false)},400)}}>X</div>
								<div className='newsLetter-content'>
									<div className='newsLetter'>
										<div className='newsLetter-text'>
											<h2>Would you like to subscribe to our newsletter?</h2>
											<p>Subscribe now to get notified about new features, special offers and promo codes.</p>
											<form onSubmit={subscribe} className='flex-row center-cross wrap' style={{gap:'17px'}}>
												<input onChange={(e)=>setNewsletterMail(e.target.value)} value={newsletterMail} required={true} type="email" placeholder='Drop your email'/>
												<button type='submit' className='btn font-white back-orange'>{subState===true ? <img style={{height:
												'15px'}} src='/assets/Icons/spinner.gif' alt='loding'/> : subButton}</button>
											</form>
											<span style={{fontSize:'0.75rem'}}>We won't pass your details on anyone else. By clicking the "subscribe" button, you agree to our "<a rel="noopener noreferrer" target='_blank' href="https://www.storesyncer.com/termsConditions">Terms and Conditions</a>" and "<a rel="noopener noreferrer" target='_blank' href="https://www.storesyncer.com/privacypolicy">Privacy Policy</a>"</span>
										</div>
										{/* <div className='demo-image'>
											<img src="/assets/Images/videoPlaceholder.jpg" alt="" />
										</div> */}
									</div>
								</div>
							</div>
						:
							''
						}

					</div>
				}>

				</Route>
				<Route exact path='/termsConditions' element={
					<TermsConditions/>
				}>
				</Route>
				<Route exact path='/cookiepolicy' element={
					<CookiePolicy/>
				}>
				</Route>
				<Route exact path='/privacypolicy' element={
					<PrivacyPolicy/>
				}>
				</Route>
				<Route  path='/*' element={
					<div style={{height:'100vh', width:'100vw', backgroundColor:'white'}} className='flex-column center-all'>
						<img src="/assets/Icons/404.svg" alt="404" />
					</div> 
				}>
				</Route>
			</Routes>
		</div>
	</Router>




   
  );
}

export default App;
