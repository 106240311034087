import ReactGA from "react-ga4";

export const initGA = (id) => {
    ReactGA.initialize(id);
}

export const sendMessageFloatingFormSuccess = () =>{
    ReactGA.event({
        category: "Contact",
        action: "Sent Message Success",
        label: "Floating Form Success",
    })
}  
export const sendMessageFloatingFormFail = () =>{
    ReactGA.event({
        category: "Contact",
        action: "Sent Message Failed",
        label: "Floating Form Failed",
    })
}  



//opened sign up gape
 
export const goToApp = () =>{
    ReactGA.event({
        category: "App",
        action: "Sign Up Page Opened",
        label: "User Opened Sign Up Page",
    })
    ReactGA.event({
        category: "App",
        action: "Sign Up From Navigation",
        label: "Used Sign Up Button",
    })
}  
export const goToApp2 = () =>{
    ReactGA.event({
        category: "App",
        action: "Sign Up Page Opened",
        label: "User Opened Sign Up Page",
    })
    ReactGA.event({
        category: "App",
        action: "Sign Up From Get Started",
        label: "Used Get Started Button",
    })
}  
export const goToApp3 = () =>{
    ReactGA.event({
        category: "App",
        action: "Sign Up Page Opened",
        label: "User Opened Sign Up Page",
    })
    ReactGA.event({
        category: "App",
        action: "Sign Up From Footer",
        label: "Used Footer Button",
    })
}  
export const goToApp4 = () =>{
    ReactGA.event({
        category: "App",
        action: "Sign Up Page Opened",
        label: "User Opened Sign Up Page",
    })
    ReactGA.event({
        category: "App",
        action: "Sign Up From Free Table",
        label: "Used Table Free",
    })
}
export const goToApp5 = () =>{
    ReactGA.event({
        category: "App",
        action: "Sign Up Page Opened",
        label: "User Opened Sign Up Page",
    })
    ReactGA.event({
        category: "App",
        action: "Sign Up From Trial Button",
        label: "Used Table Trial",
    })
}
export const goToApp6 = () =>{
    ReactGA.event({
        category: "App",
        action: "Sign Up Page Opened",
        label: "User Opened Sign Up Page",
    })
    ReactGA.event({
        category: "App",
        action: "Sign Up From Premium Plus",
        label: "Used Table Premium Plus",
    })
}



//doc page
export const docVisitedNav = () =>{
    ReactGA.event({
        category: "Doc",
        action: "Doc Page Opened",
        label: "User Opened Doc Page",
    })
    ReactGA.event({
        category: "Doc",
        action: "Doc Page From Nav",
        label: "Doc Page Opened from Header",
    })
}
export const docVisitedFooter = () =>{
    ReactGA.event({
        category: "Doc",
        action: "Doc Page Opened",
        label: "User Opened Doc Page",
    })
    ReactGA.event({
        category: "Doc",
        action: "Doc Page From Footer",
        label: "Doc Page Opened from Footer",
    })
}

export const docVisitedBenefits = (label) =>{
    ReactGA.event({
        category: "Doc",
        action: "Doc Page Opened",
        label: "User Opened Doc Page",
    })
    ReactGA.event({
        category: "Doc",
        action: "Doc Page From"+" "+label,
        label: label,
    })
}

export const docVisitedFeatures = (label) =>{
    ReactGA.event({
        category: "Doc",
        action: "Doc Page Opened",
        label: "User Opened Doc Page",
    })
    ReactGA.event({
        category: "Doc",
        action: "Doc Page From"+" "+label,
        label: label,
    })
}



//use case videos
export const useCasesWatched = (label) =>{
    ReactGA.event({
        category: "Video",
        action: "Use Case Video Opened",
        label: "User Watched Use Case Video",
    })
    ReactGA.event({
        category: "Video",
        action: "Use Case Video"+" "+label,
        label: label,
    })
}